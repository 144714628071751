import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const DealerSettings = {
  bdf: {
    logo: '/logo/bdf.png',
    bg: '/bg/wfl.jpg',
    name: 'Boundary Ford',
  },
  ff: {
    logo: '/logo/ff.jpg',
    bg: '/bg/wfl.jpg',
    name: 'Freedom Ford',
  },
  ifd: {
    logo: '/logo/ifd.png',
    bg: '/bg/wfl.jpg',
    name: 'Island Ford',
  },
  mcf: {
    logo: '/logo/mcf.png',
    bg: '/bg/wfl.jpg',
    name: 'Maclin Ford',
  },
  mtf: {
    logo: '/logo/mtf.png',
    bg: '/bg/wfl.jpg',
    name: 'Midtown Ford',
  },
  pgm: {
    logo: '/logo/pgm.png',
    bg: '/bg/wfl.jpg',
    name: 'Prince George Motors',
  },
  wfl: {
    logo: '/logo/wfl.png',
    bg: '/bg/wfl.jpg',
    name: 'Waterloo Ford Lincoln',
  },
  lak: {
    logo: '/logo/lak.png',
    bg: '/bg/lak.jpg',
    name: 'Lakewood Chevrolet',
  },
  spg: {
    logo: '/logo/spg.png',
    bg: '/bg/spg.jpg',
    name: 'Sherwood Buick GMC',
  },
  spc: {
    logo: '/logo/spc.png',
    bg: '/bg/lak.jpg',
    name: 'Sherwood Park Chevrolet',
  },
  tnw: {
    logo: '/logo/tnw.png',
    bg: '/bg/toyota.jpg',
    name: 'Toyota Northwest Edmonton',
  },
  spt: {
    logo: '/logo/spt.jpg',
    bg: '/bg/toyota.jpg',
    name: 'Sherwood Park Toyota',
  },
  sbt: {
    logo: '/logo/sbt.png',
    bg: '/bg/toyota.jpg',
    name: 'Scarborough Toyota',
  },  
  sva: {
    logo: '/logo/sva.jpg',
    bg: '/bg/acura.jpg',
    name: 'Southview Acura',
  },    
  top: {
    logo: '/logo/top.jpg',
    bg: '/bg/toyota.jpg',
    name: 'Toyota On The Park',
  },  
  woh: {
    logo: '/logo/woh.jpg',
    bg: '/bg/honda.jpg',
    name: 'Whitby Oshawa Honda',
  },    
  wnt: {
    logo: '/logo/wnt.jpg',
    bg: '/bg/wntbg.jpg',
    name: 'Wilson Niblett',
  },      
  klc: {
    logo: '/logo/klc.jpg',
    bg: '/bg/lak.jpg',
    name: 'Kelowna Chevrolet',
  },  
  klt: {
    logo: '/logo/klt.jpg',
    bg: '/bg/toyota.jpg',
    name: 'Kelowna Toyota',
  },  
  spm: {
    logo: '/logo/spm.png',
    bg: '/bg/spmbg.png',
    name: 'Sherwood Motorcars',
  },      
  subs: {
    logo: '/logo/subs.png',
    bg: '/bg/subs.jpg',
    name: 'Subaru Of Saskatoon',    
  },
  lotp: {
    logo: '/logo/lotp.jpg',
    bg: '/bg/lexus.jpg',
    name: 'Lexus On The Park',
  },  
  pgs: {
    logo: '/logo/pgs.png',
    bg: '/bg/subs.jpg',
    name: 'Subaru Of Prince George',    
  },
  sce: {
    logo: '/logo/sce.png',
    bg: '/bg/subs.jpg',
    name: 'Subaru City',    
  },  
  rse: {
    logo: '/logo/rse.png',
    bg: '/bg/subs.jpg',
    name: 'Rally Subaru - Subaru Edmonton',    
  },    
  jgo: {
    logo: '/logo/jgo.png',
    bg: '/bg/lak.jpg',
    name: 'Jim Wilson Chevrolet',    
  },    
  ggc: {
    logo: '/logo/ggc.png',
    bg: '/bg/lak.jpg',
    name: 'Georgian Chev',
  },
};

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
  },
  media: {
    width: '100%',
    padding: '30px',
  },
  tos: {
    marginTop: '40px',
  },
  policy: {
    textDecoration: "underline",
    cursor: "pointer"
  }
});

const Home = (props) => {
  // const utm_str = "?utm_camapign=wifi_landing_page&utm_medium=COAG_digital_properties&utm_source=wifi_landing_page&utm_content=connect_now_button";
  const [dealerUrl, setDurl] = useState('');
  const [open, setOpen] = useState(false);

  const url = props.location.search;
  const params = queryString.parse(url);

  const { challenge, mac, apmac, uamip, uamport } = params;
  const { code } = props.match.params;

  const dealerSettings = DealerSettings[code];

  const classes = useStyles();

  const getDigest = async (cha, mac, apmac, dealerCode, apip, apport) => {
    let res = await axios.post('api/login/wpr', {
      info: `challenge=${cha}`,
      mac,
      apmac,
      dealerCode,
      apip,
      apport,
    });
    const { dUrl } = res.data;
    setDurl(dUrl);

    //console.log('getDigest', dUrl);
  };

  useEffect(() => {
    getDigest(challenge, mac, apmac, code, uamip, uamport);
  }, [challenge, mac, apmac, code, uamip, uamport]);

  const showPolicy = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{'Free Customer Internet Terms of Service'}</DialogTitle>
        <DialogContent>
          <DialogContentText variant="body2">
            By clicking the accept button, below, the user agrees to comply with
            and to be legally bound by the terms of this Policy. If this Policy
            or any terms of the Service are unacceptable or become unacceptable
            to the user, the user's only right shall be to terminate his or her
            use of the Service. The user specifically agrees to the following
            conditions: The user will use the Service only as permitted by
            applicable local, state, federal, and International laws. The user
            will refrain from any actions that we consider to be negligent or
            malicious.
          </DialogContentText>
          <DialogContentText variant="body2">
            Lawful Use: The Service may only be used for lawful purposes and in
            a manner which we believe to be consistent with the rights of other
            users. The Service shall not be used in a manner which would violate
            any law or infringe any copyright, trademark, trade secret, right of
            publicity, privacy right, or any other right of any person or
            entity. The Service shall not be used for the purpose of accessing,
            transmitting, or storing material which is considered obscene,
            libelous or defamatory. Illegal acts may subject users to
            prosecution by local, state, federal, or international authorities.
            We may bring legal action to enjoin violations of this Policy and/or
            to collect damages, if any, caused by violations.
          </DialogContentText>
          <DialogContentText variant="body2">
            The user specifically agrees to the following conditions: The user
            will use the Service only as permitted by applicable local, state,
            federal, and International laws. The user will refrain from any
            actions that we consider to be negligent or malicious. The user will
            not send email containing viruses or other malicious or damaging
            software. The user will run appropriate anti-virus software to
            remove such damaging software from his or her computer. The user
            will not access web sites which contain material that is grossly
            offensive to us, including clear expressions of bigotry, racism, or
            hatred. The user will not access web sites which contain material
            that defames, abuses, or threatens others.{' '}
          </DialogContentText>
          <DialogContentText variant="body2">
            Changes to Service: We reserve the right to change the Service
            offered, the features of the Service offered, the terms of this
            Policy, or its system without notice to the user.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="container"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundImage: `url('${dealerSettings.bg}')`,
        }}
      >
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={`${dealerSettings.logo}`}
              title="logo"
              component="img"
              alt="logo"
            />
          </CardActionArea>

          <CardContent>
            <Typography gutterBottom variant="h6" component="h2" align="center">
              Free Customer Internet
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              component="p"
              className={classes.tos}
            >
              By clicking the ACCEPT button at the bottom of this page, you
              acknowledge that you understand and agree to this{' '}
              <a onClick={showPolicy} className={classes.policy} >Policy</a>.
            </Typography>
            <Typography align="center">
              <div className="btn-tos">
                <a className="btn btn-text" href={`${dealerUrl}`}>
                  Accept & Connect
                </a>
                <br />
              </div>
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Home;
