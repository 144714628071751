import React from 'react';

const Start = () => {
  return (
    <div>
          <h1>START</h1>
          <p>v4.9</p>
    </div>
  );
};

export default Start;
