import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router';
import Home from '../components/Home';
import LoggedIn from '../components/LoggedIn';
import Start from '../components/Start';
import { CssBaseline } from '@material-ui/core';

import './style.scss';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Fragment>
        <CssBaseline />
        <Route exact path="/" component={Start} />
        <Route exact path="/dealer/:code/" component={Home} />
        <Route exact path="/loggedIn" component={LoggedIn} />
      </Fragment>
    );
  }
}
