import React, { Component, useLayoutEffect } from 'react';
import queryString from 'query-string';
import { Typography, Container } from '@material-ui/core';

const LoggedIn = props => {
    let utm_str = "?utm_campaign=wifi_landing_page&utm_medium=COAG_digital_properties&utm_source=wifi_landing_page&utm_content=connect_now_button";
  let url = props.location.search;
  let params = queryString.parse(url);

  useLayoutEffect(() => {
      setTimeout(() => {
          window.location.assign(`${params.durl}${utm_str}`);
    }, 5000);
  }, []);

  return (
    <div className="loggedin__container">
      <img className="header__logo" src={`./logo/${params.d}.png`} />
      <Typography>You are now connected to the Internet</Typography>
    </div>
  );
};

export default LoggedIn;
